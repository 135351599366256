import React, { Component } from 'react';

import { GlobalDispatchContext } from '../context/globalContextProvider';
import SVG from './svg';

import styles from '../components/singleProduct.module.css';
import Highlights from './highlights';

class SingleProduct extends Component {
	state = {
		articleNumber: 1,
		inCart: false,
	};

	handleInputChange = e => {
		const articleNumber = e.target.value;
		if (articleNumber <= 10 && articleNumber >= 0)
			this.setState({ articleNumber });
		return;
	};

	handleArticleNumberButton = e => {
		const articleNumber = this.state.articleNumber;
		const newArticleNumber =
			e.target.innerHTML === '-' ? articleNumber - 1 : articleNumber + 1;
		if (newArticleNumber <= 10 && newArticleNumber >= 1)
			this.setState({ articleNumber: newArticleNumber });
	};

	handleClick = () => {
		this.setState({ inCart: true });
	};

	handleSubmit = e => {
		e.preventDefault();
		const { articleNumber } = this.state;
		const { id, thumbnail, title, size, price, path } = this.props.frontmatter;
		const cartItem = Object.assign(
			{ id },
			{ thumbnail },
			{ title },
			{ price },
			{ size },
			{ amount: articleNumber },
			{ path }
		);

		this.context({ type: 'CREATE_CART_ITEM', item: cartItem });
	};

	static contextType = GlobalDispatchContext;

	render() {
		const { frontmatter, html } = this.props;
		const {
			thumbnail,
			title,
			size,
			price,
			stock,
			ribbon,
			ribbonActive,
		} = frontmatter;
		const stockInfo = stock < 10 ? `Only ${stock} items left` : 'มีสินค้า';

		return (
			<section className={styles.section}>
				<div className={styles.wrapper}>
					<div className={styles.imageWrapper}>
						<img className={styles.image} src={thumbnail} alt={title} />
						{ribbonActive ? (
							<span className={styles.ribbon}>{ribbon}</span>
						) : null}
					</div>
					<div className={styles.productDetailsWrapper}>
						<div>
							<h1 className={styles.title}>{title.toUpperCase()}</h1>
							<div className={styles.size}>{size}ml</div>

							<div className={styles.price}>
								<span className={styles.currency}>฿</span>
								<span>{price}</span>
							</div>
							<small>{stockInfo}</small>
						</div>

						<form onSubmit={this.handleSubmit}>
							<div className={styles.articleNumberWrapper}>
								<button
									type="button"
									className={styles.articleNumberButton}
									onClick={this.handleArticleNumberButton}
								>
									-
								</button>
								<button
									type="button"
									className={styles.articleNumberButton}
									onClick={this.handleArticleNumberButton}
								>
									+
								</button>
								<input
									type="number"
									className={styles.articleNumber}
									value={this.state.articleNumber}
									onChange={this.handleInputChange}
								/>
							</div>
							<button
								className={styles.addCartbutton}
								type="submit"
								onClick={this.handleClick}
							>
								{!this.state.inCart ? (
									<>
										<span>ใส่ลงตะกร้า</span>
										<SVG name="addCart" width="17" height="17" fill="#fff" />
									</>
								) : (
									<>
										<span>สินค้าได้เพิ่มลงในตะกร้าเรียบร้อยแล้ว</span>
										<SVG name="checkmark" width="17" height="17" fill="#fff" />
									</>
								)}
							</button>
						</form>

						<Highlights description={html} />
					</div>
				</div>
			</section>
		);
	}
}

export default SingleProduct;
