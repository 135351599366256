import React from 'react';

function PaymentInfo() {
	return (
		<div>
			<div style={{ marginBottom: '1.5rem' }}>
				<strong>แบบที่ 1) ลูกค้าสามารถโอนเงินผ่านทางธนาคารได้</strong>
				<p style={{ marginBottom: '1.5rem' }}>
					โดยหลังจากการโอนเงิน ลูกค้าต้องส่งหลักฐานการโอนเงิน ผ่านทางเว็บไซต์
					โดยเลือกเมนู “แจ้งการโอนเงิน"
				</p>
				<div
					style={{ display: 'flex', marginLeft: '.1rem', marginBottom: '1rem' }}
				>
					<img
						style={{ borderRadius: '.15rem', marginTop: '.15rem' }}
						src="/images/scb.jpeg"
						width="40"
						height="40"
					/>
					<div style={{ marginLeft: '1rem' }}>
						<h5>ธนาคารไทยพาณิชย์</h5>
						<p>
							<span style={{ display: 'block' }}>
								ชื่อบัญชี: นางสาว สวรินทร์ นาคจู
							</span>
							<span style={{ display: 'block' }}>
								เลขที่บัญชี: 069-236148-0
							</span>
							<span style={{ display: 'block' }}>ประเภท: ออมทรัพย์</span>
							<span style={{ display: 'block' }}>
								สาขา: มหาวิทยาลัยเกษตรศาสตร์
							</span>
						</p>
					</div>
				</div>
				<div style={{ display: 'flex', marginLeft: '.1rem' }}>
					<img
						style={{ borderRadius: '.15rem', marginTop: '.15rem' }}
						src="/images/bank.jpeg"
						width="40"
						height="40"
					/>
					<div style={{ marginLeft: '1rem' }}>
						<h5>ธนาคารกรุงเทพ</h5>
						<p>
							<span style={{ display: 'block' }}>
								ชื่อบัญชี: นางสาว สวรินทร์ นาคจู
							</span>
							<span style={{ display: 'block' }}>
								เลขที่บัญชี: 043-7-47521-3
							</span>
							<span style={{ display: 'block' }}>ประเภท: ออมทรัพย์</span>
							<span style={{ display: 'block' }}>
								สาขา: มหาวิทยาลัยเกษตรศาสตร์
							</span>
						</p>
					</div>
				</div>
			</div>
			<strong>แบบที่ 2) ชำระเงินอัตโนมัติผ่านทาง Paypal</strong>
			<p>
				หากลูกค้าพบเจอปัญหาต่างๆ โปรดเลือกเมนู “ติดต่อเรา"
				พร้อมกับทิ้งข้อความชี้แจง
				เพื่อที่ทางพนักงานจะได้ให้ความช่วยเหลือท่านต่อไป
			</p>
		</div>
	);
}

export default PaymentInfo;
