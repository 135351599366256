import React from 'react';
import StandardDelivery from '../svgs/standardDelivery';
import ExpressDelivery from '../svgs/expressDelivery';
import styles from './deliveryInfo.module.css';

function DeliveryInfo() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.col} style={{ marginLeft: '.25rem' }}>
				<StandardDelivery height={25} width={25} fill="#555" />
				<div style={{ marginLeft: '1rem' }}>
					<strong>ส่งแบบลงทะเบียน</strong>
					<p>ใช้เวลา 4-7 วัน (ขึ้นอยู่กับระยะทาง)</p>
				</div>
			</div>
			<div style={{ display: 'flex' }}>
				<ExpressDelivery height={25} width={25} fill="#555" />
				<div style={{ marginLeft: '1rem' }}>
					<strong>ส่งแบบEMS (ด่วนพิเศษ)</strong>
					<p style={{ paddingRight: '1rem' }}>
						ใช้เวลา 1-3 วัน (ขึ้นอยู่กับระยะทาง)
					</p>
				</div>
			</div>
		</div>
	);
}

export default DeliveryInfo;
