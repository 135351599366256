import React from 'react';
import SEO from '../components/seo';
import SingleProduct from '../components/singleProduct';
import Layout from '../components/layout';

function Template({ data }) {
	const { markdownRemark } = data;
	const { frontmatter, html } = markdownRemark;
	return (
		<Layout>
			<SEO title={frontmatter.title} />
			<SingleProduct frontmatter={frontmatter} html={html} />
		</Layout>
	);
}

export const pageQuery = graphql`
	query ProductsByPath($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				id
				title
				thumbnail
				size
				price
				stock
				sale
				path
				ribbon
				ribbonActive
			}
		}
	}
`;

export default Template;
