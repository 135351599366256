import React, { Component } from 'react';
import PaymentInfo from './paymentInfo';
import DeliveryInfo from './deliveryInfo';
import styles from './highlights.module.css';

class Highlights extends Component {
	state = {
		tabs: [
			{
				name: 'รายละเอียดสินค้า',
				content: (
					<div dangerouslySetInnerHTML={{ __html: this.props.description }} />
				),
				active: true,
			},
			{ name: 'การจัดส่งสินค้า', content: <DeliveryInfo />, active: false },
			{ name: 'วิธีการชำระเงิน', content: <PaymentInfo />, active: false },
		],
	};

	handleTabClick = e => {
		const tabs = this.state.tabs;
		const clickedTab = e.target.dataset.itemTab;
		const index = tabs.findIndex(tab => tab.name === clickedTab);
		tabs[index].active = !tabs[index].active;
		this.setState({ tabs });
	};

	render() {
		const { tabs } = this.state;
		const tabStyle = {
			fontWeight: '500',
		};
		const chevronStyle = { transform: 'rotate(270deg)' };

		return (
			<div className={styles.wrapper}>
				{tabs.map(tab => {
					return (
						<div key={tab.name}>
							<button
								className={styles.button}
								style={tab.active ? tabStyle : null}
								data-item-tab={tab.name}
								onClick={this.handleTabClick}
							>
								<h4 style={{ marginBottom: '0', pointerEvents: 'none' }}>
									{tab.name}
								</h4>
								<span
									className={styles.chevron}
									style={tab.active ? chevronStyle : null}
								>
									&rsaquo;
								</span>
							</button>
							{tab.active ? (
								<div className={styles.content}>
									<div>{tab.content}</div>
								</div>
							) : null}
						</div>
					);
				})}
			</div>
		);
	}
}

export default Highlights;
